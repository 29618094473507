<template>
  <div>
    <section class="w-full  p-3 bg-white rounded-md  text-center">
      <div class="flex items-center justify-between">
        <div class="tab-items">
          <div
            v-for="tab in getTabs"
            :key="`tab-item-${tab.id}`"
            :class="getTabClass(tab.id)"
            v-text="tab.text"
            @click="onClickTab(tab.id)"
          />
        </div>
        <div v-if="getReportData.length > 0" @click="handleExportReport">
          <i
            class="fas fa-file-export cursor-pointer
          "
            title="Export"
          ></i>
        </div>
      </div>
    </section>
    <div
      :key="`vehicle-bulk-report`"
      class=" mt-3 pt-5 border-oLightestGray border-t-2"
    >
      <STable
        :sId="'vehicle-bulk-report'"
        :headers="vehicleBulkReportHeaders"
        :dataCount="getReportData.length"
        :isShowNoResultsView="true"
      >
        <STableRow
          :key="vehicleReportItemIndex"
          v-for="(vehicleReportItem, vehicleReportItemIndex) in getReportData"
          text-fallback-always
        >
          <STableRowItem>
            <template v-if="vehicleReportItem.success">
              <router-link
                :to="{
                  name: 'ViewVehicleProfile',
                  params: { id: vehicleReportItem.created_vehicle_id },
                }"
                class="text-blue-600"
                target="_blank"
              >
                {{ vehicleReportItem.qr_code }}
              </router-link>
            </template>
            <template v-else>
              {{ vehicleReportItem.qr_code }}
            </template>
          </STableRowItem>

          <STableRowItem>
            <template v-if="vehicleReportItem.success">
              <i class=" text-green-500 fas fa-check-circle" />
            </template>
            <template v-else>
              <i class=" text-oRed fas fa-exclamation-circle" />
            </template>
          </STableRowItem>
          <STableRowItem :text="vehicleReportItem.failure_reason" />
        </STableRow>
      </STable>
    </div>
  </div>
</template>

<script>
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import { saveAs } from 'file-saver'
export default {
  name: 'AddMultipleScooterStep3',
  components: {
    STable,
    STableRow,
    STableRowItem,
  },
  props: {
    reportData: {
      required: true,
      default: () => [],
    },
  },
  data: function() {
    return {
      isLoading: false,
      activeTabId: 'all',
      vehicleBulkReportHeaders: [
        {
          text: 'Qr Code',
          width: '20%',
          sort: null,
        },
        {
          text: 'Status',
          width: '10%',
          sort: null,
        },
        {
          text: 'Failed Reason',
          width: '70%',
          sort: null,
        },
      ],
      csvReportColumnHeader: [
        { key: 'iot_name', title: 'IOT Name*' },
        { key: 'iot_manufacturer_name', title: 'IOT Manufacturer*' },
        { key: 'iot_category_display_text', title: 'IOT Type*' },
        { key: 'imei', title: 'IOT IMEI*' },
        { key: 'iot_firmware_type', title: 'IOT Firmware Type' },
        { key: 'iot_firmware_key', title: 'IOT Firmware Key' },
        { key: 'iot_iccid_number', title: 'IOT ICCID Number' },
        { key: 'iot_phone_number', title: 'IOT Phone Number' },
        { key: 'vehicle_type_display_text', title: 'Vehicle Type*' },
        { key: 'vehicle_name', title: 'Vehicle Name*' },
        { key: 'vehicle_manufacturer_name', title: 'Vehicle Manufacturer*' },
        { key: 'qr_code', title: 'Vehicle QR Code*' },
        { key: 'vehicle_model_name', title: 'Vehicle Model*' },
        { key: 'failure_reason', title: 'Failed Reason (Remove)' },
      ],
    }
  },
  computed: {
    getTabs() {
      return [
        {
          id: 'all',
          text: `All (${this.reportData.total})`,
        },
        {
          id: 'success',
          text: `Success (${this.reportData.success_count})`,
        },
        {
          id: 'failed',
          text: `Failed (${this.reportData.failure_count})`,
        },
      ]
    },
    getReportData() {
      if (this.activeTabId === 'success') {
        const successData = this.reportData.data.filter((item) => item.success)
        return successData
      } else if (this.activeTabId === 'failed') {
        const failedData = this.reportData.data.filter((item) => !item.success)
        return failedData
      } else {
        return this.reportData.data
      }
    },
  },
  methods: {
    getTabClass(tabId) {
      return {
        'tab-item': true,
        'tab-item--active': this.activeTabId === tabId,
      }
    },
    async onClickTab(tabId) {
      this.activeTabId = tabId
    },
    csvMaker() {
      const csvRows = []

      const headers = this.csvReportColumnHeader.map((x) => x.title)

      csvRows.push(headers.join(';'))
      for (let i = 0; i < this.getReportData.length; i++) {
        let rowData = []
        this.csvReportColumnHeader.map((headerItem) => {
          if (this.getReportData[i][headerItem.key]) {
            rowData.push(this.getReportData[i][headerItem.key])
          } else {
            rowData.push('')
          }
        })

        const values = rowData.join(';')
        csvRows.push(values)
      }

      return csvRows.join('\n')
    },
    handleExportReport() {
      const blob = new Blob([this.csvMaker()], { type: 'text/csv' })
      const fileName = `${this.activeTabId}_${this.getReportData.length}.csv`
      saveAs(blob, fileName)
    },
    submit() {
      this.$emit('save', { step: 3, data: {} })
    },
  },
}
</script>
<style lang="scss" scoped>
.tab-items {
  @apply flex max-w-sm rounded-full bg-gray-200 py-1 px-2 gap-2 transition-all duration-200 ease-in-out;

  .tab-item {
    min-width: 80px;
    @apply flex items-center justify-center text-center text-xs cursor-pointer py-2 px-2 font-medium text-gray-700 rounded-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900;

    &--active {
      @apply bg-black text-white;
      &:hover {
        @apply bg-black text-white;
      }
    }
  }
}
</style>
